import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, element } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  ensureCurrentUser,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import { UserCard, SimpleDialog } from '../../components';
import { EnquiryForm } from '../../forms';

import {
  Page,
  PageBootstrap,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  VideoEmbed,
  EmailConfirmationContents,
  SimpleDialogWithTitle,
  FavoriteButton,
} from '../../components';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './listingpage.scss';

import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  switchFavoriteStatus,
} from './ListingPage.duck';
import SectionHostMaybe from './SectionHostMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import Lightbox from 'react-image-lightbox';
import { Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faLeaf,
  faMapMarkedAlt,
  faUserFriends,
  faMountain,
  faYenSign,
  faBus,
  faTrain,
  faFlag,
  faCar,
 
} from '@fortawesome/free-solid-svg-icons';

import { faCirclePlay } from "@fortawesome/pro-regular-svg-icons"

import { getPricingLabel } from './utils';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { currentUser, enquiryModalOpenForListingId, params, listingId } = props;

    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      emailConfirmationOpen: false,
      isLightBoxOpen: false,
      photoIndex: 0,
      scrollPosition: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSwitchFavoriteStatus = this.onSwitchFavoriteStatus.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('react-image-lightbox/style.css');
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      const position = window.pageYOffset;
      this.setState({ scrollPosition: position });
    }
  };

  openEmailVerification() {
    this.setState({ emailConfirmationOpen: true });
  }
  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { numberOfPersons, bookingDates, ...bookingData } = values;

    bookingData.numberOfPersons = numberOfPersons;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      numberOfPersons,
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    //email confirmation thing
    if (currentUser && !currentUser.attributes.emailVerified) {
      this.openEmailVerification();
      return;
    }

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      if (!currentUser.attributes.emailVerified) {
        this.openEmailVerification();
        return;
      } else {
        this.setState({ enquiryModalOpen: true });
      }
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, callSetInitialValues } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  onSwitchFavoriteStatus = () => {
    const {
      currentUser,
      location,
      history,
      callSetInitialValues,
      params,
      onSwitchFavoriteStatus,
    } = this.props;
    const listingId = new UUID(params.id);

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      //callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });
      callSetInitialValues(setInitialValues, {});

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);

      return;
    } else {
      onSwitchFavoriteStatus(listingId.uuid, currentUser);
    }
  };

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onResendVerificationEmail,
      isFavorite,
    } = this.props;

    const innerWidth = () => {
      if (typeof window !== 'undefined') {
        return window.innerWidth;
      }
    };

    const lightText =
      innerWidth() > 650
        ? this.state.scrollPosition < 650
        : this.state.scrollPosition < innerWidth();
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <p className="experienceTitle">
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        })}
      </p>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = (
      <TopbarContainer
        lightText={lightText}
        bigText={intl.formatMessage({ id: 'ListingPage.experiencesTitle' })}
      />
    );

    if (showListingError && (showListingError.status === 404 || showListingError.status === 400)) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      const addToFavorites = intl.formatMessage({ id: 'ListingPage.addToFavorites' });
      const addedToFavorites = intl.formatMessage({ id: 'ListingPage.addedToFavorites' });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className="pageRoot">
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>

            <LayoutWrapperMain>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh', color: 'var(--failColor)' }}
              >
                <Grid item xs={3}>
                  <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
                </Grid>
              </Grid>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className="pageRoot">
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className="loadingText">
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const user = ensureCurrentUser(currentUser);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const activitiesOptions = findOptionsForSelectFilter('activities', filterConfig);
    const stylesOptions = findOptionsForSelectFilter('styles', filterConfig);
    const listingActivities = publicData && publicData.activities;
    const activityName =
      activitiesOptions &&
      activitiesOptions.length > 0 &&
      listingActivities &&
      activitiesOptions.find(a => a.key === listingActivities[0])?.label;
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className="separator">•</span>
        </span>
      ) : null;

    const { isLightBoxOpen, photoIndex } = this.state;

    const imagesOrder = currentListing?.attributes?.publicData?.imagesOrder;

    const images = currentListing.images;

    //TODO replace with listingImages.. and reorder
    const imagesArray = imagesOrder
      ? imagesOrder.map((i, index) => {
          const found = images.find(img => img.id.uuid === i.id);
          return index === 0
            ? found?.attributes.variants['landscape-crop4x']?.url
            : found?.attributes.variants['landscape-crop2x']?.url;
        })
      : images.map((i, index) => {
          return index === 0
            ? i.attributes.variants['landscape-crop4x']?.url
            : i.attributes.variants['landscape-crop2x']?.url;
        });

    const firstImage = imagesArray.length > 0 && imagesArray[0];
    const facebookImages = imagesArray.map(i => {
      return { url: i, width: 1200, height: 630 };
    });
    const twitterImages = facebookImages;
    const schemaImages = JSON.stringify(imagesArray);

    const openMediaButtonMessage = intl.formatMessage({
      id: 'ListingPage.openMediaButtonMessage',
    });

    const showingArrayData = element => {
      return <div className="smallItemWrapper">■ {element}</div>;
    };

    //Requirements
    const requirements = publicData && publicData.requirements;
    const arrayOfRequirement = requirements?.split('|');

    //Equipments
    const equipment = publicData && publicData.equipment;
    const arrayOfEquipment = equipment?.split('|');

    //Facilities
    const facilities = publicData && publicData.facilities;
    const arrayOfFacilities = facilities?.split('|');

    //Access info
    const accessInfo = publicData && publicData.accessInfo;
    const arrayOfAccessInfo = accessInfo?.split('|');

    //Headline
    const headline = publicData && publicData.headline;

    const findId = (options, key) => options.find(s => publicData[key] == s.key)?.id;

    //experience length
    const experienceLengthOptions = findOptionsForSelectFilter('experienceLength', filterConfig);

    const experienceLengthId = findId(experienceLengthOptions, 'experienceLength');

    //season
    const monthsOptionsIntl = findOptionsForSelectFilter('monthsOptions', filterConfig);
    const seasonStartId = findId(monthsOptionsIntl, 'seasonStart');
    const seasonEndId = findId(monthsOptionsIntl, 'seasonEnd');

    const address = publicData && publicData.location && publicData.location.address;
    const shortAddress = address && [address.split(',')[1]].join('');
    const groupMin = publicData && publicData.groupMin;
    const groupMax = publicData && publicData.groupMax;

    //experience type
    const experienceTypeIntl = findOptionsForSelectFilter('experienceType', filterConfig);
    const experienceTypeId = findId(experienceTypeIntl, 'experienceType');

    //price/person
    const amount = price && price.amount;
    const currency = price && price.currency;

    const stylesArray =
      (publicData &&
        publicData?.styles &&
        stylesOptions.filter(s => publicData?.styles.includes(s.key))) ||
      [];
    const pickUp = publicData && publicData.pickUp;
    const nearestStation = publicData && publicData.nearestStation;
    const distanceByCar = publicData && publicData.distanceByCar;
    const showOnePic = typeof window !== 'undefined' && window.innerWidth < 1000;
    const meetingPoint = publicData && publicData.meetingPoint;
    const locationDescription = publicData && publicData.locationDescription;
    const itinerary = publicData && publicData.itinerary;
    const youtubeLink = publicData?.youtubeLink;
    const pricingLabel = getPricingLabel(currentListing, amount, intl);

    const onCloseVerification = () => this.setState({ emailConfirmationOpen: false });

    return (
      <PageBootstrap
        description={description}
        title={schemaTitle}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        // facebookImages={[{ url: metaImage, width: 1200, height: 630 }]}
        // twitterImages={[{ url: `${config.canonicalRootURL}${metaImage}`, width: 600, height: 314 }]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Website',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <SimpleDialogWithTitle
          titleId="ListingPage.emailConfirmation"
          open={this.state.emailConfirmationOpen}
          onClose={onCloseVerification}
          buttonId="ListingPage.emailButton"
        >
          <EmailConfirmationContents
            onResendVerificationEmail={onResendVerificationEmail}
            user={user}
            onClick={onCloseVerification}
          />
        </SimpleDialogWithTitle>

        {isLightBoxOpen && (
          <Lightbox
            className="fixed-top"
            mainSrc={imagesArray[photoIndex]}
            nextSrc={imagesArray[(photoIndex + 1) % imagesArray.length]}
            prevSrc={imagesArray[(photoIndex + imagesArray.length - 1) % imagesArray.length]}
            onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + imagesArray.length - 1) % imagesArray.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imagesArray.length,
              })
            }
          />
        )}

        <TopbarContainer />
        <nav className="secondbar">
          <Row className="inner d-flex flex-row">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <p className="page-title align-items-center justify-content-start">Experiences</p>
            </Col>
          </Row>
        </nav>

        <Container fluid className="content">
          <Row className="hero">
            <Col lg={8} md={8} sm={8} xs={12} className="herorow">
              <img className="fullHeightImage" src={firstImage} loading="lazy" />
            </Col>
            <Col lg={4} md={4} sm={4} xs={12} className="halfrow">
              <img className="halfHeightImage" src={imagesArray[1]} loading="lazy" />
              <img className="halfHeightImage" src={imagesArray[2]} loading="lazy" />
            </Col>
          </Row>
          <div className="openGallery d-flex align-items-center justify-content-center">
            <a onClick={() => this.setState({ isLightBoxOpen: true })} className="openGalleryLink d-flex align-items-center">
              <FontAwesomeIcon icon={faCirclePlay} className="me-2 icon-baseline-adjust" />
              {openMediaButtonMessage}
            </a>
          </div>




          <div className="titleWrapper">
            <Row className="titleSection">
              <Col lg={8} md={8} sm={8} xs={9} className="titleNameAndHeadline">
                <p className="activityTypeLabel">{activityName}</p>
                {richTitle}
              </Col>

              <Col lg={4} md={4} sm={4} xs={3} className="titleFavButton">
                <FavoriteButton
                  switchFavoriteStatus={this.onSwitchFavoriteStatus}
                  isFavorite={isFavorite(currentListing.id)}
                />
              </Col>
            </Row>
          </div>
        </Container>

        <Container fluid className="topContentWrapper">
          <Row className="contentSection">
            <Col lg={8} md={7} sm={12} xs={12} className="mainContent">
              {headline && <p className="headline">{headline}</p>}
              {youtubeLink && <VideoEmbed className="video" videoUrl={youtubeLink} />}

              <div className="dataBlockSection well">
                <Row>
                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="dataBlockIcon"
                    ></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.durationLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {experienceLengthId ? (
                          <FormattedMessage id={experienceLengthId} />
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon icon={faLeaf} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.seasonLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {seasonStartId && seasonEndId ? (
                          intl.formatMessage({ id: seasonStartId }) +
                          ' ' +
                          intl.formatMessage({ id: 'ListingPage.toTag' }) +
                          ' ' +
                          intl.formatMessage({ id: seasonEndId }) +
                          ' ' +
                          intl.formatMessage({ id: 'ListingPage.toTagTwo' })
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      className="dataBlockIcon"
                    ></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.locationLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {locationDescription ? (
                          locationDescription
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon
                      icon={faUserFriends}
                      className="dataBlockIcon"
                    ></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.personsLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {groupMin && groupMax ? (
                          groupMin +
                          ' ' +
                          intl.formatMessage({ id: 'ListingPage.personsTagOne' }) +
                          ' ' +
                          intl.formatMessage({ id: 'ListingPage.toTag' }) +
                          ' ' +
                          groupMax +
                          ' ' +
                          intl.formatMessage({ id: 'ListingPage.personsTag' })
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon icon={faMountain} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.tourTypeLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {experienceTypeId ? (
                          <FormattedMessage id={experienceTypeId} />
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6} xs={6} className="dataBlock">
                    <FontAwesomeIcon icon={faYenSign} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.amountLabel" />
                      </p>
                      <p className="dataBlockInfo">{pricingLabel}</p>
                    </div>
                  </Col>
                </Row>

                <div className="kammuiStyleBlock">
                  <p className="themesLabel">Kammui Style:</p>
                  <p className="themesText">
                    {stylesArray.map(s => {
                      return (
                        <span key={s.key} className="styleLink">
                          <NamedLink
                            name="SearchPage"
                            params={{ locale: config.locale }}
                            to={{ search: `?pub_styles=has_any%3A${s.key}` }}
                          >
                            {s.label}
                          </NamedLink>
                        </span>
                      );
                    })}
                  </p>
                </div>
              </div>

              <SectionHostMaybe
                title={title}
                listing={currentListing}
                authorDisplayName={authorDisplayName}
                onContactUser={this.onContactUser}
                isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                sendEnquiryError={sendEnquiryError}
                sendEnquiryInProgress={sendEnquiryInProgress}
                onSubmitEnquiry={this.onSubmitEnquiry}
                currentUser={currentUser}
                onManageDisableScrolling={onManageDisableScrolling}
              />

              <div className="aboutSection">
                <h2 className="sectionTitle">
                  <FormattedMessage id="ListingPage.about" />
                </h2>
                <p className="descriptionText">{description}</p>
              </div>
            </Col>

            <Col lg={4} md={5} sm={12} xs={12} className="subContent">
              <div className="contactSection">
                <BookingPanel
                  className="bookingPanel"
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentListing={currentListing}
                />
              </div>

              <div className="contactSection">
                <p className="contactTitle">
                  <FormattedMessage id="ListingPage.contactTitle" />
                </p>
                <p className="contactSubtitle">
                  <FormattedMessage id="ListingPage.contactSubtitle" />
                </p>

                <div className="enquiryButton" onClick={this.onContactUser}>
                  <FormattedMessage id="ListingPage.enquiryButtonMessage" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="photoContentWrapper">
          <Row className="contentSection">
            {imagesArray.map(i => {
              return (
                <Col key={i} lg={3} md={3} sm={4} xs={6} className="image">
                  <a onClick={() => this.setState({ isLightBoxOpen: true })}>
                    <img loading="lazy" src={i} className="smallImage" />
                  </a>
                </Col>
              );
            })}
          </Row>
        </Container>

        <Container fluid className="bottomContentWrapper">
          <Row className="contentSection">
            <Col lg={6} md={6} sm={6} xs={12} className="mainContent">
              <div className="scheduleSection">
                <div className="smallTitle">
                  <FormattedMessage id="ListingPage.schedule" />
                </div>
                <p className="lineBreakWrapper">{itinerary}</p>
              </div>

              <div className="participationConditionsSection">
                <div className="smallTitle">
                  <FormattedMessage id="ListingPage.participationConditions" />
                </div>
                <p className="lineBreakWrapper">{requirements}</p>
              </div>

              <div className="equipmentSection">
                <div className="smallTitle">
                  <FormattedMessage id="ListingPage.equipmentSectionTitle" />
                </div>
                <p className="lineBreakWrapper">{equipment}</p>
              </div>

              <div className="facilityInfoSection">
                <div className="smallTitle">
                  <FormattedMessage id="ListingPage.facilityInfoSectionTitle" />
                </div>
                <p className="lineBreakWrapper">{facilities}</p>
              </div>
            </Col>

            <Col lg={6} md={6} sm={6} xs={12} className="subContent">
              <Row className="accessDataBlock">
                <Col className="accessInfoTitle">
                  <div className="smallTitle">
                    <FormattedMessage id="ListingPage.accessSectionTitle" />
                  </div>
                  <p className="lineBreakWrapper">{accessInfo}</p>
                </Col>

                <Row className="accessInfoItems">
                  <Col lg={6} md={6} sm={12} xs={12} className="accessInfoItem">
                    <FontAwesomeIcon icon={faBus} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.pickUpLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {pickUp ? pickUp : <FormattedMessage id="ListingPage.notProvided" />}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12} className="accessInfoItem">
                    <FontAwesomeIcon icon={faTrain} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.nearestStationLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {nearestStation ? (
                          nearestStation
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12} className="accessInfoItem">
                    <FontAwesomeIcon icon={faFlag} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.addressLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {meetingPoint ? (
                          meetingPoint
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12} className="accessInfoItem">
                    <FontAwesomeIcon icon={faCar} className="dataBlockIcon"></FontAwesomeIcon>
                    <div className="dataBlockValue">
                      <p className="dataBlockLabel">
                        <FormattedMessage id="ListingPage.distanceByCarLabel" />
                      </p>
                      <p className="dataBlockInfo">
                        {distanceByCar ? (
                          distanceByCar
                        ) : (
                          <FormattedMessage id="ListingPage.notProvided" />
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>

                <div className="mapSection">
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>

        <Footer showBanner={false} />
      </PageBootstrap>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const isFavorite = listingId => {
    return currentUser
      ? currentUser.attributes.profile.privateData.favListingsArray?.find(
          id => id === listingId.uuid
        )
      : false;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    isFavorite,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onSwitchFavoriteStatus: (listingId, currentUser) =>
    dispatch(switchFavoriteStatus(listingId, currentUser)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
