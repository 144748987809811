function formatAmount(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const getPricingLabel = (listing, initialAmount, intl) => {
  const publicData = listing?.attributes?.publicData;

  const variablePricingOptions = publicData?.variablePricingOptions;

  if (variablePricingOptions && variablePricingOptions?.length > 0) {
    //we push the original price to variablePricingOptions
    variablePricingOptions.push({
      numberOfPersons: '1',
      price: initialAmount,
    });
    const prices = variablePricingOptions.map(i => i.price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);

    const finalLabel =
      `${formatAmount(min)} - ${formatAmount(max)}` +
      ' ' +
      intl.formatMessage({ id: 'ListingPage.jpy' });
    return finalLabel;
  } else if (!variablePricingOptions && initialAmount) {
    const finalLabel =
      formatAmount(initialAmount) + ' ' + intl.formatMessage({ id: 'ListingPage.jpy' });
    return finalLabel;
  } else {
    return <FormattedMessage id="ListingPage.notProvided" />;
  }
};
